<template>
  <div id="site">
    <RouterContainer v-if="get_prod_mode" />
    <PlaceholderPage v-if="!get_prod_mode" />
  </div>
</template>

<script>
import RouterContainer from "./pages/RouterContainer.vue";
import PlaceholderPage from "./pages/placeholder/PlaceholderPage.vue";

export default {
  name: "App",
  components: { RouterContainer, PlaceholderPage },
  mounted() {},
  data() {
    return { prod: false };
  },
  methods: {},
  computed: {
    get_prod_mode: function () {
      return this.prod;
    },
  },
};
</script>

<style>
body {
  margin: 0;
}

#site {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  background-color: whitesmoke;
}
</style>
